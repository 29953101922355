exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-contact-submit-js": () => import("./../../../src/pages/contact-submit.js" /* webpackChunkName: "component---src-pages-contact-submit-js" */),
  "component---src-pages-disclaimer-js": () => import("./../../../src/pages/disclaimer.js" /* webpackChunkName: "component---src-pages-disclaimer-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-work-index-js": () => import("./../../../src/pages/work/index.js" /* webpackChunkName: "component---src-pages-work-index-js" */),
  "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-src-assets-work-abu-index-de-mdx": () => import("./../../../src/pages/work/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/liliarusterholtz/Documents/GitHub/portfolio2024/src/assets/work/ABU/index_de.mdx" /* webpackChunkName: "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-src-assets-work-abu-index-de-mdx" */),
  "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-src-assets-work-abu-index-en-mdx": () => import("./../../../src/pages/work/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/liliarusterholtz/Documents/GitHub/portfolio2024/src/assets/work/ABU/index_en.mdx" /* webpackChunkName: "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-src-assets-work-abu-index-en-mdx" */),
  "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-src-assets-work-cost-of-living-index-de-mdx": () => import("./../../../src/pages/work/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/liliarusterholtz/Documents/GitHub/portfolio2024/src/assets/work/CostOfLiving/index_de.mdx" /* webpackChunkName: "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-src-assets-work-cost-of-living-index-de-mdx" */),
  "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-src-assets-work-cost-of-living-index-en-mdx": () => import("./../../../src/pages/work/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/liliarusterholtz/Documents/GitHub/portfolio2024/src/assets/work/CostOfLiving/index_en.mdx" /* webpackChunkName: "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-src-assets-work-cost-of-living-index-en-mdx" */),
  "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-src-assets-work-data-drawing-index-de-mdx": () => import("./../../../src/pages/work/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/liliarusterholtz/Documents/GitHub/portfolio2024/src/assets/work/DataDrawing/index_de.mdx" /* webpackChunkName: "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-src-assets-work-data-drawing-index-de-mdx" */),
  "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-src-assets-work-data-drawing-index-en-mdx": () => import("./../../../src/pages/work/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/liliarusterholtz/Documents/GitHub/portfolio2024/src/assets/work/DataDrawing/index_en.mdx" /* webpackChunkName: "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-src-assets-work-data-drawing-index-en-mdx" */),
  "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-src-assets-work-eiermarkt-index-de-mdx": () => import("./../../../src/pages/work/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/liliarusterholtz/Documents/GitHub/portfolio2024/src/assets/work/Eiermarkt/index_de.mdx" /* webpackChunkName: "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-src-assets-work-eiermarkt-index-de-mdx" */),
  "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-src-assets-work-eiermarkt-index-en-mdx": () => import("./../../../src/pages/work/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/liliarusterholtz/Documents/GitHub/portfolio2024/src/assets/work/Eiermarkt/index_en.mdx" /* webpackChunkName: "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-src-assets-work-eiermarkt-index-en-mdx" */),
  "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-src-assets-work-fcm-index-de-mdx": () => import("./../../../src/pages/work/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/liliarusterholtz/Documents/GitHub/portfolio2024/src/assets/work/FCM/index_de.mdx" /* webpackChunkName: "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-src-assets-work-fcm-index-de-mdx" */),
  "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-src-assets-work-fcm-index-en-mdx": () => import("./../../../src/pages/work/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/liliarusterholtz/Documents/GitHub/portfolio2024/src/assets/work/FCM/index_en.mdx" /* webpackChunkName: "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-src-assets-work-fcm-index-en-mdx" */),
  "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-src-assets-work-fcm-natural-ventilation-index-de-mdx": () => import("./../../../src/pages/work/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/liliarusterholtz/Documents/GitHub/portfolio2024/src/assets/work/FCM-NaturalVentilation/index_de.mdx" /* webpackChunkName: "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-src-assets-work-fcm-natural-ventilation-index-de-mdx" */),
  "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-src-assets-work-fcm-natural-ventilation-index-en-mdx": () => import("./../../../src/pages/work/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/liliarusterholtz/Documents/GitHub/portfolio2024/src/assets/work/FCM-NaturalVentilation/index_en.mdx" /* webpackChunkName: "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-src-assets-work-fcm-natural-ventilation-index-en-mdx" */),
  "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-src-assets-work-gottlieber-christmas-index-de-mdx": () => import("./../../../src/pages/work/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/liliarusterholtz/Documents/GitHub/portfolio2024/src/assets/work/GottlieberChristmas/index_de.mdx" /* webpackChunkName: "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-src-assets-work-gottlieber-christmas-index-de-mdx" */),
  "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-src-assets-work-gottlieber-christmas-index-en-mdx": () => import("./../../../src/pages/work/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/liliarusterholtz/Documents/GitHub/portfolio2024/src/assets/work/GottlieberChristmas/index_en.mdx" /* webpackChunkName: "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-src-assets-work-gottlieber-christmas-index-en-mdx" */),
  "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-src-assets-work-gottlieber-easter-index-de-mdx": () => import("./../../../src/pages/work/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/liliarusterholtz/Documents/GitHub/portfolio2024/src/assets/work/GottlieberEaster/index_de.mdx" /* webpackChunkName: "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-src-assets-work-gottlieber-easter-index-de-mdx" */),
  "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-src-assets-work-gottlieber-easter-index-en-mdx": () => import("./../../../src/pages/work/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/liliarusterholtz/Documents/GitHub/portfolio2024/src/assets/work/GottlieberEaster/index_en.mdx" /* webpackChunkName: "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-src-assets-work-gottlieber-easter-index-en-mdx" */),
  "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-src-assets-work-gottlieber-royal-index-de-mdx": () => import("./../../../src/pages/work/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/liliarusterholtz/Documents/GitHub/portfolio2024/src/assets/work/GottlieberRoyal/index_de.mdx" /* webpackChunkName: "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-src-assets-work-gottlieber-royal-index-de-mdx" */),
  "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-src-assets-work-gottlieber-royal-index-en-mdx": () => import("./../../../src/pages/work/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/liliarusterholtz/Documents/GitHub/portfolio2024/src/assets/work/GottlieberRoyal/index_en.mdx" /* webpackChunkName: "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-src-assets-work-gottlieber-royal-index-en-mdx" */),
  "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-src-assets-work-gottlieber-saisonal-index-de-mdx": () => import("./../../../src/pages/work/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/liliarusterholtz/Documents/GitHub/portfolio2024/src/assets/work/GottlieberSaisonal/index_de.mdx" /* webpackChunkName: "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-src-assets-work-gottlieber-saisonal-index-de-mdx" */),
  "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-src-assets-work-gottlieber-saisonal-index-en-mdx": () => import("./../../../src/pages/work/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/liliarusterholtz/Documents/GitHub/portfolio2024/src/assets/work/GottlieberSaisonal/index_en.mdx" /* webpackChunkName: "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-src-assets-work-gottlieber-saisonal-index-en-mdx" */),
  "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-src-assets-work-gottlieber-spezialitaeten-index-de-mdx": () => import("./../../../src/pages/work/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/liliarusterholtz/Documents/GitHub/portfolio2024/src/assets/work/GottlieberSpezialitaeten/index_de.mdx" /* webpackChunkName: "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-src-assets-work-gottlieber-spezialitaeten-index-de-mdx" */),
  "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-src-assets-work-gottlieber-spezialitaeten-index-en-mdx": () => import("./../../../src/pages/work/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/liliarusterholtz/Documents/GitHub/portfolio2024/src/assets/work/GottlieberSpezialitaeten/index_en.mdx" /* webpackChunkName: "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-src-assets-work-gottlieber-spezialitaeten-index-en-mdx" */),
  "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-src-assets-work-gottlieber-tradition-index-de-mdx": () => import("./../../../src/pages/work/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/liliarusterholtz/Documents/GitHub/portfolio2024/src/assets/work/GottlieberTradition/index_de.mdx" /* webpackChunkName: "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-src-assets-work-gottlieber-tradition-index-de-mdx" */),
  "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-src-assets-work-gottlieber-tradition-index-en-mdx": () => import("./../../../src/pages/work/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/liliarusterholtz/Documents/GitHub/portfolio2024/src/assets/work/GottlieberTradition/index_en.mdx" /* webpackChunkName: "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-src-assets-work-gottlieber-tradition-index-en-mdx" */),
  "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-src-assets-work-innofield-index-de-mdx": () => import("./../../../src/pages/work/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/liliarusterholtz/Documents/GitHub/portfolio2024/src/assets/work/Innofield/index_de.mdx" /* webpackChunkName: "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-src-assets-work-innofield-index-de-mdx" */),
  "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-src-assets-work-innofield-index-en-mdx": () => import("./../../../src/pages/work/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/liliarusterholtz/Documents/GitHub/portfolio2024/src/assets/work/Innofield/index_en.mdx" /* webpackChunkName: "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-src-assets-work-innofield-index-en-mdx" */),
  "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-src-assets-work-lonza-index-de-mdx": () => import("./../../../src/pages/work/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/liliarusterholtz/Documents/GitHub/portfolio2024/src/assets/work/Lonza/index_de.mdx" /* webpackChunkName: "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-src-assets-work-lonza-index-de-mdx" */),
  "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-src-assets-work-lonza-index-en-mdx": () => import("./../../../src/pages/work/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/liliarusterholtz/Documents/GitHub/portfolio2024/src/assets/work/Lonza/index_en.mdx" /* webpackChunkName: "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-src-assets-work-lonza-index-en-mdx" */),
  "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-src-assets-work-muespacherstrassenfest-index-de-mdx": () => import("./../../../src/pages/work/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/liliarusterholtz/Documents/GitHub/portfolio2024/src/assets/work/Muespacherstrassenfest/index_de.mdx" /* webpackChunkName: "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-src-assets-work-muespacherstrassenfest-index-de-mdx" */),
  "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-src-assets-work-muespacherstrassenfest-index-en-mdx": () => import("./../../../src/pages/work/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/liliarusterholtz/Documents/GitHub/portfolio2024/src/assets/work/Muespacherstrassenfest/index_en.mdx" /* webpackChunkName: "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-src-assets-work-muespacherstrassenfest-index-en-mdx" */),
  "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-src-assets-work-re-imagining-cities-index-de-mdx": () => import("./../../../src/pages/work/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/liliarusterholtz/Documents/GitHub/portfolio2024/src/assets/work/ReImaginingCities/index_de.mdx" /* webpackChunkName: "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-src-assets-work-re-imagining-cities-index-de-mdx" */),
  "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-src-assets-work-re-imagining-cities-index-en-mdx": () => import("./../../../src/pages/work/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/liliarusterholtz/Documents/GitHub/portfolio2024/src/assets/work/ReImaginingCities/index_en.mdx" /* webpackChunkName: "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-src-assets-work-re-imagining-cities-index-en-mdx" */),
  "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-src-assets-work-swiss-scales-index-de-mdx": () => import("./../../../src/pages/work/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/liliarusterholtz/Documents/GitHub/portfolio2024/src/assets/work/SwissScales/index_de.mdx" /* webpackChunkName: "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-src-assets-work-swiss-scales-index-de-mdx" */),
  "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-src-assets-work-swiss-scales-index-en-mdx": () => import("./../../../src/pages/work/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/liliarusterholtz/Documents/GitHub/portfolio2024/src/assets/work/SwissScales/index_en.mdx" /* webpackChunkName: "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-src-assets-work-swiss-scales-index-en-mdx" */),
  "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-src-assets-work-wcs-index-de-mdx": () => import("./../../../src/pages/work/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/liliarusterholtz/Documents/GitHub/portfolio2024/src/assets/work/WCS/index_de.mdx" /* webpackChunkName: "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-src-assets-work-wcs-index-de-mdx" */),
  "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-src-assets-work-wcs-index-en-mdx": () => import("./../../../src/pages/work/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/liliarusterholtz/Documents/GitHub/portfolio2024/src/assets/work/WCS/index_en.mdx" /* webpackChunkName: "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-src-assets-work-wcs-index-en-mdx" */),
  "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-src-assets-work-written-images-index-de-mdx": () => import("./../../../src/pages/work/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/liliarusterholtz/Documents/GitHub/portfolio2024/src/assets/work/WrittenImages/index_de.mdx" /* webpackChunkName: "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-src-assets-work-written-images-index-de-mdx" */),
  "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-src-assets-work-written-images-index-en-mdx": () => import("./../../../src/pages/work/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/liliarusterholtz/Documents/GitHub/portfolio2024/src/assets/work/WrittenImages/index_en.mdx" /* webpackChunkName: "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-src-assets-work-written-images-index-en-mdx" */)
}

